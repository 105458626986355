.message-container {
  padding: 4rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
}
.message-body {
  margin-top: 10px;
  display: flex;
  position: relative;
  gap: 20px;
  justify-content: center;
}
.sub_heading {
  font-size: 1rem;
}
.message-image > img {
  height: 100%;
  width: 200px;
  object-fit: cover;
}
.message-content {
  display: flex;
  background: var(--main-color);
}
.message {
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  max-width: 500px;
}

.message > span {
  position: relative;
}
.message > :nth-child(2) {
  color: rgb(226, 225, 225);
  text-align: left;
  font-size: 0.9rem;
}
.message > :nth-child(3) {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}
.message-details-body {
  margin: 5rem 8rem;
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: flex-start;
  gap: 1rem;
}

.message-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
}
.message-details > div > p {
  background-color: #ffffff;
  padding: 1.5rem;
  text-align: justify;
  font-weight: 400;
  color: #665656;
  line-height: 28px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
}

.message-details-image {
  border-radius: 50%;
  text-align: center;
}
.message-details-image > img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 998px) {
  .message-container {
    padding: 2rem;
    height: 100%;
  }
  .message-body {
    flex-direction: column;
  }
  .message-image {
    width: 100%;
    background: #a7b2b6;
    height: 180px;
    display: flex;
    justify-content: center;
  }

  .message-image > img {
    height: 180px;
    width: 180px;
    object-fit: cover;
  }
  .message-content {
    flex-direction: column;
  }
  .message > :nth-child(3) {
    position: unset;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
  }
  .message-details {
    margin: 1rem;
  }
  .message-details-body {
    margin: 3rem 1rem;
  }
}
